exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-formsent-contact-js": () => import("./../../../src/pages/formsent-contact.js" /* webpackChunkName: "component---src-pages-formsent-contact-js" */),
  "component---src-pages-formsent-js": () => import("./../../../src/pages/formsent.js" /* webpackChunkName: "component---src-pages-formsent-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ithojo-form-js": () => import("./../../../src/pages/ithojo-form.js" /* webpackChunkName: "component---src-pages-ithojo-form-js" */),
  "component---src-pages-ithojo-js": () => import("./../../../src/pages/ithojo.js" /* webpackChunkName: "component---src-pages-ithojo-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

